import Vue from 'vue'
import { projectAuth } from '@/firebase/config'

import router from './router'
import store from './store'

import '@/plugins/errorHandler'
import '@/plugins/casl'
import i18n from '@/plugins/i18n'
import vuetify from '@/plugins/vuetify'
import '@/plugins/apexchart'
import '@/plugins/ui-components'

import App from './App.vue'
import '@/styles/main.scss'
import '@/styles/styles.scss'

Vue.config.productionTip = false
Vue.prototype.CDN_BASE_URL = process.env.VUE_APP_CDN_ASSETS_BASE_URL

let app = ''
// initialize app after firebase setup
projectAuth.onAuthStateChanged(async () => {
  if (!app) {
    app = new Vue({
      vuetify,
      router,
      store,
      i18n,
      render: h => h(App),
    }).$mount('#app')
  }
})

if (window.Cypress) {
  // be visible only during e2e tests
  window.app = app
}
