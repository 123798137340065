import { AbilityBuilder, Ability } from '@casl/ability'
import * as libs from './libs'

export const ability = new Ability()
export function abilityHelper(permissions = [], additional = false) {
  const isArray = Array.isArray(permissions)
  const isStringArray = permissions.every(key => typeof key === 'string')

  if (!isArray || !isStringArray) {
    permissions = []
  }

  let ability_ = new AbilityBuilder()
  if (additional) {
    ability_ = ability
  }

  permissions.forEach(key => ability_.can('access', key))
  ability.update(ability_.rules)
}

export const NestedGeneralPermissions = Object.keys({}).reduce((acc, key) => {
  const nestedPermissions = libs.splitUnderlineToNestedObject(key)
  return libs.mergeObjectsRecursively(acc, nestedPermissions)
}, {})
