import { projectAuth } from '@/firebase/config'

export const beforeEachRouterEntry = async (to, _from, next) => {
  const currentUser = projectAuth.currentUser
  const requiresAuth = to.matched.some(record => record?.meta?.requiresAuth)

  if (requiresAuth && !currentUser && to.name !== 'Login') {
    let query

    if (!to.path.includes('/login')) {
      sessionStorage.setItem('interface-recovery--redirectPath', to.path)
      query = to.query
    }

    if (_from.name === 'Login') {
      return
    }

    return next({ name: 'Login', query })
  }

  if (!requiresAuth && currentUser) {
    return next({ name: 'Main' })
  }

  return next()
}

export default { beforeEachRouterEntry }
