import Vue from 'vue'

import { getAccount, getAccountMe, putAccountMe } from '@/services/account'

/**
 * @typedef {'free' | 'basic' | 'advanced' | 'enterprise'} PackageType
 */

/**
 * @type {import('vuex').Module<any, any>}
 */
export const UserModule = {
  namespaced: true,

  state: () => ({
    account: {},
    client: {
      id: null,
      permissions: [],

      /**
       * @type {PackageType}
       */
      planID: 'basic',
      paymentMethod: 'other',
    },

    _profile: {},
    profile: {
      id: null,
      name: null,
      photo: null,
      email: null,
      language: null,
    },
  }),

  getters: {
    profile: state => {
      return {
        id: state.profile.id,
        name: state.profile.name,
        photo: state.profile.photo,
        email: state.profile.email,
      }
    },

    language: state => state.profile.language,

    planID: state => state.client.planID,
    paymentMethod: state => state.client.paymentMethod,
    permissions: state => state.client.permissions || [],

    isBasicPlanAdminUser: state => {
      const isAdmin = !!state.client?.isAdmin
      const isBasic = state.client.planID === 'basic'

      return isAdmin && isBasic
    },
  },

  actions: {
    logoutAccount({ commit, state }) {
      commit('SET_CLIENT', {
        id: null,
        permissions: [],

        planID: 'basic',
        paymentMethod: 'other',
      })

      commit('SET_PROFILE', {
        id: null,
        name: 'anonymous',
        photo: null,
        email: null,
        language: state.profile.language,
      })

      commit('SET_ACCOUNT', {})
      commit('SET_PROFILE_', {})
    },

    async putAccountMe({ commit }, payload) {
      return putAccountMe(payload).then(() => {
        commit('SET_PROFILE_LANGUAGE', payload.language)
      })
    },

    async getAccountMe({ dispatch, commit, state }, payload) {
      if (!payload?.refresh && state.client?.id) {
        return Promise.resolve(state._profile)
      }

      return getAccountMe().then(async ({ data }) => {
        commit('SET_CLIENT', {
          id: data.id,
          permissions: data.permissions,

          planID: data.planID,
          paymentMethod: 'other',
        })

        commit('SET_PROFILE', {
          id: data.id,
          name: data.name,
          photo: data.photo,
          email: data.email,
          language: data.language,
        })

        commit('SET_PROFILE_', data)

        dispatch('getAccount', payload)

        return data
      })
    },

    async getAccount({ commit, state }, payload) {
      if (!payload?.refresh && state.account) {
        return Promise.resolve(state.account)
      }

      return getAccount().then(({ data }) => {
        commit('SET_ACCOUNT', data)

        return data
      })
    },
  },

  mutations: {
    SET_ACCOUNT(state, payload) {
      Vue.set(state, 'account', payload)
    },

    SET_CLIENT(state, payload) {
      Vue.set(state, 'client', payload)
    },

    SET_PROFILE_(state, payload) {
      Vue.set(state, '_profile', payload)
    },

    SET_PROFILE(state, payload) {
      Vue.set(state, 'profile', payload)
    },

    SET_PROFILE_LANGUAGE(state, payload) {
      Vue.set(state.profile, 'language', payload)
    },
  },
}
