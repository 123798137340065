<template>
  <v-app>
    <v-main class="page">
      <v-container fluid>
        <transition>
          <router-view ref="PageView" class="full-height"></router-view>
        </transition>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import _EventBus from '@/plugins/eventBus'

import { projectAuth } from '@/firebase/config'

import { abilityHelper } from '@/helpers/ability'
import {
  calcLanguageToApi,
  calcLanguageToLocale,
  saveLanguage,
} from '@/helpers/locale'

const MainPageRouterName = 'RecognitionPage'

export default {
  name: 'App',

  provide() {
    return {
      handleChangeLanguage: this.handleChangeLanguage,
      handleSyncLanguage: this.handleSyncLanguage,
      handleSignOut: this.handleSignOut,
    }
  },

  created() {
    _EventBus.$on('bus:alert', this.displayAlert)

    this.handleChangeLanguage(false)
    this.onAuthStateChangedByLogin()
  },

  beforeDestroy() {
    _EventBus.$off('bus:alert')
  },

  methods: {
    onAuthStateChangedByLogin() {
      // wait for login
      this.$store.dispatch('signedInLogout')

      projectAuth.onAuthStateChanged(this.onAuthStateChangedNextOrObserver)
    },

    /**
     * @param {import('@firebase/auth').User} User
     */
    onAuthStateChangedNextOrObserver(User) {
      if (!User) {
        this.handleLogout()
        return
      }

      this.handleSignIn({ refresh: true })
    },

    handleLogout() {
      this.$store.dispatch('signedInLogout')
      this.$store.dispatch('currentUser/logoutAccount')
    },

    async handleSignOut() {
      return new Promise(resolve => {
        this.handleLogout()

        projectAuth.signOut().then(() => {
          if (!window.location.href.includes('login')) {
            this.$router.push({ name: 'Login' })
          }

          resolve()
        })
      })
    },

    async handleSignIn(payload) {
      this.handleLoginPageMethods('setLoading', true)

      /* eslint-disable no-unused-vars */
      return this.$store
        .dispatch('currentUser/getAccountMe', payload)
        .then(data => {
          const lang = calcLanguageToLocale(data.language)
          this.handleChangeLanguage(lang)

          // abilityHelper([]) // @TODO Em Branco
          abilityHelper(data.permissions)

          this.$store.dispatch('signedInLogin')

          this.defineRouteGuardWatch()

          this.handleFromLoginToPage()
        })
        .catch(_error => {
          // User is signed out.
          this.handleSignOut().then(() => {
            this.handleLoginPageMethods('customErrorMessage')
          })
        })
        .finally(() => {
          this.handleLoginPageMethods('setLoading', false)
        })
      /** eslint-enable no-unused-vars */
    },

    handleFromLoginToPage() {
      const from = sessionStorage.getItem('interface-recovery--redirectPath')

      if (from) {
        sessionStorage.removeItem('interface-recovery--redirectPath')
        this.$router.replace(from)

        return
      }

      if (!window.location.href.includes('login')) {
        return
      }

      this.$router.push({ name: 'Main' })
    },

    handleLoginPageMethods(methodName, ...payload) {
      this.$nextTick(() => {
        if (!window.location.href.includes('login')) {
          return
        }

        const method = this.$refs.PageView?.[methodName]
        if (typeof method !== 'function') {
          return
        }

        method(...payload)
      })
    },

    defineRouteGuardWatch() {
      return this.$watch('$route.matched', this.handleRouteGuard, {
        immediate: true,
      })
    },

    async handleRouteGuard(matched) {
      const canPagePermissions = this.calcRoutePermissions(matched)
      const shouldGuardRoute = Object.keys(canPagePermissions).length
      const hasPermission = Object.values(canPagePermissions).every(
        permission => permission === true
      )

      if (shouldGuardRoute && !hasPermission) {
        this.goNextRoutePossible(MainPageRouterName)
      }

      return shouldGuardRoute && !hasPermission
    },

    /**
     * @param {Array} matched
     */
    calcRoutePermissions(matched) {
      const requiresPermissions = Array.from(
        new Set(matched.flatMap(record => record?.meta?.requiresPermission))
      ).filter(Boolean)

      const canPagePermissions = requiresPermissions.reduce(
        (obj, permission) => {
          const canPermission = this.$can('access', permission)
          return { ...obj, [permission]: canPermission }
        },
        {}
      )

      return canPagePermissions
    },

    /**
     * @param {String} RouteName
     */
    goNextRoutePossible(RouteName) {
      const PageRouter = this.$router.options.routes.find(
        r => r.name === RouteName
      )

      const PageChilds =
        PageRouter?.children?.reduce((obj, page) => {
          const matched = [PageRouter, page]
          const hasPermission = Object.values(
            this.calcRoutePermissions(matched)
          ).every(permission => permission === true)

          return { ...obj, [page.name]: hasPermission }
        }, {}) || {}

      const nextRoutePossible = Object.keys(PageChilds).find(
        key => PageChilds[key] === true
      )

      if (!nextRoutePossible) {
        return this.handleSignOut().then(() => {
          this.handleLoginPageMethods('customErrorMessage', {
            text: 'pageLogin.errorPermission.text',
          })
        })
      }

      return this.$router.push(nextRoutePossible)
    },

    /**
     * @param {String} lang
     */
    async handleSyncLanguage(lang) {
      const language = calcLanguageToApi(lang)

      return this.$store
        .dispatch('currentUser/putAccountMe', { language })
        .then(() => {
          this.handleChangeLanguage(lang)
        })
    },

    /**
     * @param {String} lang
     */
    async handleChangeLanguage(lang) {
      const { language, reloadByNotEqSession } = saveLanguage(lang)
      this.$root.$i18n.locale = language

      if (!reloadByNotEqSession) {
        return
      }

      const { l: urlLang = false } = this.$route.query
      const preventUrlNotEqSession = calcLanguageToLocale(urlLang) !== language
      if (urlLang && preventUrlNotEqSession) {
        return
      }

      window.location.reload()
    },

    displayAlert(payload) {
      if (!payload) return

      this.$root.$emit('display-alert', payload)
    },
  },
}
</script>
