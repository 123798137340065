const LoginPage = () =>
  import(/* webpackChunkName: "LoginPage" */ '@/pages/Login/Login.vue')
const NotFoundPage = () =>
  import(/* webpackChunkName: "NotFoundPage" */ '@/pages/NotFound/NotFound.vue')

const RecognitionPage = () =>
  import(
    /* webpackChunkName: "RecognitionPage" */ '@/views/Recognition/Recognition.vue'
  )
const MuralPage = () =>
  import(/* webpackChunkName: "RecognitionPage" */ '@/pages/Mural/Mural.vue')
const MuralPageComponentAll = () =>
  import(
    /* webpackChunkName: "RecognitionPage" */ '@/pages/Mural/Childrens/MuralMessageNewPost/MuralMessageNewPost.vue'
  )
const MuralPageComponentAchievement = () =>
  import(
    /* webpackChunkName: "RecognitionPage" */ '@/pages/Mural/Childrens/MuralMessageNewAchievement/MuralMessageNewAchievement.vue'
  )

const RewardsPage = () =>
  import(/* webpackChunkName: "RewardsPage" */ '@/pages/Rewards/Rewards.vue')
const AllRewardsPage = () =>
  import(
    /* webpackChunkName: "RewardsPage" */ '@/pages/Rewards/Childrens/All/All.vue'
  )
const WishlistRewardsPage = () =>
  import(
    /* webpackChunkName: "RewardsPage" */ '@/pages/Rewards/Childrens/Wishlist/Wishlist.vue'
  )
const HistoryRewardsPage = () =>
  import(
    /* webpackChunkName: "RewardsPage" */ '@/pages/Rewards/Childrens/History/History.vue'
  )

const AchievementsPage = () =>
  import(
    /* webpackChunkName: "AchievementsPage" */ '@/pages/Achievements/Achievements.vue'
  )
const AllAchievementsPage = () =>
  import(
    /* webpackChunkName: "AchievementsPage" */ '@/pages/Achievements/Childrens/All/All.vue'
  )
const MeAchievementsPage = () =>
  import(
    /* webpackChunkName: "AchievementsPage" */ '@/pages/Achievements/Childrens/Me/Me.vue'
  )

const ChallengesPage = () =>
  import(
    /* webpackChunkName: "ChallengesPage" */ '@/pages/Challenges/Challenges.vue'
  )
const AllChallengesPage = () =>
  import(
    /* webpackChunkName: "ChallengesPage" */ '@/pages/Challenges/Childrens/All/All.vue'
  )
const WishlistChallengesPage = () =>
  import(
    /* webpackChunkName: "ChallengesPage" */ '@/pages/Challenges/Childrens/Wishlist/Wishlist.vue'
  )
const HistoryChallengesPage = () =>
  import(
    /* webpackChunkName: "ChallengesPage" */ '@/pages/Challenges/Childrens/History/History.vue'
  )

const AnalyticsPage = () =>
  import(
    /* webpackChunkName: "AnalyticsPage" */ '@/pages/Analytics/Analytics.vue'
  )
const OverviewAnalyticsPage = () =>
  import(
    /* webpackChunkName: "AnalyticsPage" */ '@/pages/Analytics/Childrens/Overview/Overview.vue'
  )
const DetailsAnalyticsPage = () =>
  import(
    /* webpackChunkName: "AnalyticsPage" */ '@/pages/Analytics/Childrens/Details/Details.vue'
  )

export const moduleRoutes = [
  {
    path: '/login',
    name: 'Login',
    component: LoginPage,
  },
  {
    path: '/',
    name: 'Main',
    redirect: { name: 'RecognitionPage' },
  },
  {
    path: '*',
    name: 'NotFound',
    component: NotFoundPage,
  },

  {
    path: '/',
    name: 'RecognitionPage',
    redirect: { name: 'MuralPage' },
    component: RecognitionPage,

    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: 'mural',
        name: 'MuralPage',
        redirect: { name: 'AllMuralPage' },
        component: MuralPage,
        children: [
          {
            path: '',
            name: 'AllMuralPage',
            component: MuralPageComponentAll,
          },
          {
            path: 'achievements',
            name: 'AchievementsMuralPage',
            component: MuralPageComponentAchievement,
          },
        ],
      },
      {
        path: 'rewards',
        name: 'RewardsPage',
        redirect: { name: 'AllRewardsPage' },
        component: RewardsPage,
        children: [
          {
            path: 'all/:rewardID?',
            name: 'AllRewardsPage',
            component: AllRewardsPage,
            props: true,
          },
          {
            path: 'wishlist/:rewardID?',
            name: 'WishlistRewardsPage',
            component: WishlistRewardsPage,
            props: true,
          },
          {
            path: 'history/:rewardID?',
            name: 'HistoryRewardsPage',
            component: HistoryRewardsPage,
            props: true,
          },
        ],
      },
      {
        path: 'achievements',
        name: 'AchievementsPage',
        redirect: { name: 'AllAchievementsPage' },
        component: AchievementsPage,
        children: [
          {
            path: 'all',
            name: 'AllAchievementsPage',
            component: AllAchievementsPage,
          },
          {
            path: 'me',
            name: 'MeAchievementsPage',
            component: MeAchievementsPage,
          },
        ],
      },
      {
        path: 'challenges',
        name: 'ChallengesPage',
        redirect: { name: 'AllChallengesPage' },
        component: ChallengesPage,
        children: [
          {
            path: 'all/:challengeID?',
            name: 'AllChallengesPage',
            component: AllChallengesPage,
            props: true,
          },
          {
            path: 'wishlist/:challengeID?',
            name: 'WishlistChallengesPage',
            component: WishlistChallengesPage,
            props: true,
          },
          {
            path: 'history/:challengeID?',
            name: 'HistoryChallengesPage',
            component: HistoryChallengesPage,
            props: true,
          },
        ],
      },
      {
        path: 'analytics',
        name: 'AnalyticsPage',
        redirect: { name: 'OverviewAnalyticsPage' },
        component: AnalyticsPage,
        children: [
          {
            path: 'overview',
            name: 'OverviewAnalyticsPage',
            component: OverviewAnalyticsPage,
          },
          {
            path: 'details',
            name: 'DetailsAnalyticsPage',
            component: DetailsAnalyticsPage,
          },
        ],
      },
    ],
  },
]
